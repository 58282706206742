@font-face {
  font-family: 'PT Root UI';
  src: url('../fonts/PT-Root-UI_Light.ttf') format('truetype');
  font-display: swap;
  font-weight: 300;
}
@font-face {
  font-family: 'PT Root UI';
  src: url('../fonts/PT-Root-UI_Regular.ttf') format('truetype');
  font-display: swap;
  font-weight: 400;
}
@font-face {
  font-family: 'PT Root UI';
  src: url('../fonts/PT-Root-UI_Medium.ttf') format('truetype');
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: 'PT Root UI';
  src: url('../fonts/PT-Root-UI_Bold.ttf') format('truetype');
  font-display: swap;
  font-weight: 700;
}
