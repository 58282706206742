.Pattern {
  @media (max-width: 768px) {
    margin: 0px 16px 16px;
    width: calc(100% - 16px * 2)!important;
  }
}

.FileLink{
  margin-right: 58px;

  @media (max-width: 768px) {
    margin-right: 0px;
  } 
}